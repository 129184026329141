import { createSlice ,PayloadAction ,createAction } from "@reduxjs/toolkit";

type initialState = {
    unAuthorized:boolean
    nameErrorMessage:string,
    emailErrorMessage:string,
    secondaryEmailErrorMessage:string,
    passwordErrorMessage:string,
    mobileErrorMessage:string,
    secondaryMobileErrorMessage:string,
    confirmPasswordErrorMessage:string,
    lastNameErrorMessage:string,
    preferredBHKErrorMessage:string,
    projectErrorMessage:string,
    leadMinBudgetErrorMessage:string,
    leadMaxBudgetErrorMessage:string,
    otpErrorMessage:string
}

const initialState: initialState = {
    unAuthorized: false, 
    nameErrorMessage:'',
    emailErrorMessage:'',
    secondaryEmailErrorMessage:'',
    passwordErrorMessage:'',
    mobileErrorMessage:'',
    secondaryMobileErrorMessage:'',
    confirmPasswordErrorMessage:'',
    lastNameErrorMessage:'',
    preferredBHKErrorMessage:'',
    projectErrorMessage:'',
    leadMinBudgetErrorMessage:'',
    leadMaxBudgetErrorMessage:'',
    otpErrorMessage:''
}

const ErrorSlice = createSlice({
    name:'errorMessage',
    initialState,
    reducers : {
        setUnAuthorized:(state:initialState,action:PayloadAction<boolean>) => {
            state.unAuthorized = action.payload;
        },
        setNameErrorMessage:(state:initialState,action:PayloadAction<string>) => {
            state.nameErrorMessage = action.payload;
        },
        setEmailErrorMessage:(state:initialState,action:PayloadAction<string>) => {
            state.emailErrorMessage = action.payload;
        },
        setSecondaryEmailErrorMessage:(state:initialState,action:PayloadAction<string>) => {
            state.secondaryEmailErrorMessage = action.payload;
        },
        setPasswordErrorMessage:(state:initialState,action:PayloadAction<string>) => {
            state.passwordErrorMessage = action.payload;
        },
        setMobileErrorMessage:(state:initialState,action:PayloadAction<string>) => {
            state.mobileErrorMessage = action.payload;
        },
        setSecondaryMobileErrorMessage:(state:initialState,action:PayloadAction<string>) => {
            state.secondaryMobileErrorMessage = action.payload;
        },
        setConfirmPasswordErrorMessage:(state:initialState,action:PayloadAction<string>) => {
            state.confirmPasswordErrorMessage = action.payload;
        },
        setLastNameErrorMessage:(state:initialState,action:PayloadAction<string>) => {
            state.lastNameErrorMessage = action.payload;
        },
        setPreferredBHKErrorMessage:(state:initialState,action:PayloadAction<string>) => {
            state.preferredBHKErrorMessage = action.payload;
        },
        setProjectErrorMessage:(state:initialState,action:PayloadAction<string>) => {
            state.projectErrorMessage = action.payload;
        },
        setLeadMinBudgetErrorMessage:(state:initialState,action:PayloadAction<string>) => {
            state.leadMinBudgetErrorMessage = action.payload;
        },
        setLeadMaxBudgetErrorMessage:(state:initialState,action:PayloadAction<string>) => {
            state.leadMaxBudgetErrorMessage = action.payload;
        },
        setOtpErrorMessage:(state:initialState,action:PayloadAction<string>) => {
            state.otpErrorMessage = action.payload;
        },
    }
})

export default ErrorSlice.reducer

export const {
    setUnAuthorized,
    setNameErrorMessage,
    setEmailErrorMessage,
    setSecondaryEmailErrorMessage,
    setPasswordErrorMessage,
    setMobileErrorMessage,
    setSecondaryMobileErrorMessage,
    setConfirmPasswordErrorMessage,
    setLastNameErrorMessage,
    setPreferredBHKErrorMessage,
    setProjectErrorMessage,
    setLeadMinBudgetErrorMessage,
    setLeadMaxBudgetErrorMessage,
    setOtpErrorMessage
} = ErrorSlice.actions;