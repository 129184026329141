export const PATH={
    WELCOMESCREEN : '/',
    LOGIN : '/login',
    REGISTER : '/register',
    HOME : '/home',
    LEADS : '/leads',
    ADD_LEADS:'/leads/:type?/:edit?',
    PROJECTS : '/projects',
    FORGOTPASSWORD: '/forgotpassword',

}