import React, { Suspense } from 'react';
import './index.scss';
import './scss/button.scss';
import Loading from "./pages/miscellaneous/loading/index"
import { Route, Routes } from 'react-router-dom';
import {PATH} from './routes/path'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import CustomAlert from './pages/common/customAlert';
import { useAppSelector } from './base/hooks/hooks';

const CpLayout=React.lazy(()=>import("./layouts/cpLayout/index"))
const Home = React.lazy(()=>import('./pages/home/index'))
const WelcomScreen = React.lazy(()=>import('./pages/welcomeScreen/welcomescreen'))
const Login = React.lazy(()=>import('./pages/login/login'))
const SignUp =React.lazy(()=>import('./pages/signup/signup'))
const Leads = React.lazy(()=>import('./pages/leads/leads'))
const Projects = React.lazy(()=>import('./pages/projects/projects'))
const NotFound = React.lazy(() => import("./pages/miscellaneous/notFound/index"))
const ForgotPassword = React.lazy(()=>import('./pages/forgotPassword/forgotPassword'))

function App() {
  const { showAlert } = useAppSelector((state: any) => state.AlertMessaegReducer)
  
  return (
    <div className="App">
        {showAlert.showModel||showAlert.deleteAlert?<CustomAlert />:null}   
       <Suspense fallback={<Loading/>}>
        <Routes>
        <Route path="*" element={<NotFound />} />
          <Route path={PATH.WELCOMESCREEN} element={<WelcomScreen />} />
          <Route path={PATH.LOGIN} element={<Login />} />
          <Route path={PATH.REGISTER} element={<SignUp/>}/>
          <Route path={PATH.FORGOTPASSWORD} element={<ForgotPassword />} />

          <Route element={<CpLayout/>}>
            <Route path={PATH.HOME} element={<Home />} />
            <Route path={PATH.ADD_LEADS} element={<Leads />} />
            <Route path={PATH.PROJECTS} element={<Projects />} />
          </Route>
          
        </Routes>
       </Suspense>
    </div>
  );
}

export default App;
