import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../config/index";
import { urlConstants } from "../../../constants/urlConstants";
export const loginApi = createApi({
  reducerPath: "loginApi",
  baseQuery: fetchBaseQuery({
    baseUrl: config().BASE_URL
  }),
  tagTypes: ["login"],
  
  endpoints: (builder) => ({
    login: builder.mutation<any, any>({
      query: (logindata:any) => ({
        url:urlConstants.LOGIN,
        method: "POST",
        body:logindata
      }),
      invalidatesTags: ["login"],
    })  
  }),
});

export const {
    useLoginMutation
} = loginApi
