import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../config/index";
import { urlConstants } from "../../../constants/urlConstants";
export const forgotPasswordApi = createApi({
  reducerPath: "forgotPasswordApi",
  baseQuery: fetchBaseQuery({
    baseUrl: config().BASE_URL,
  }),
  endpoints: (builder) => ({
    sendOtp: builder.mutation<any, { email: string }>({
      query: (data) => ({
        url: urlConstants.SEND_OTP,
        method: "POST",
        body: data,
      }),
    }),
    resetPassword: builder.mutation<any, { email: string, otp: string, newPassword: string }>({
      query: (data) => ({
        url: urlConstants.RESET_PASSWORD,
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const {
  useSendOtpMutation,
  useResetPasswordMutation,
} = forgotPasswordApi;
