import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { config } from '../../../config'
import { urlConstants } from '../../../constants/urlConstants'

export const projectApi = createApi({
    reducerPath: 'projectApi',
    baseQuery: fetchBaseQuery({
        baseUrl: config().BASE_URL,
        prepareHeaders: (headers: any) => {
            headers.set("Authorization", `Token ${localStorage.getItem('cp-token')}`)
            return headers
        }
    }),
    
    tagTypes: ['projects'],
    endpoints: (builder) => ({
        getProjects: builder.query<any, any>({
            query: ({searchString}:any) => ({
                url:urlConstants.PROJECTS,
                method: 'GET',
                params:{
                    searchString: searchString
                }
            }),
            providesTags: ['projects']
        }),

        
    })

})

export const { useGetProjectsQuery} = projectApi