
import { CircularProgress } from '@mui/material'
import './loader.scss'

function Index() {
  return (
    <>
          <div className='custom-content-loader'>
            <CircularProgress />
          </div>
    </>
  )
}

export default Index