import {createApi,fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import { config } from '../../../config'
import { urlConstants } from '../../../constants/urlConstants'

export const bannerApi = createApi({
    reducerPath:'bannerApi',
    baseQuery:fetchBaseQuery({
        baseUrl:config().BASE_URL,
        prepareHeaders: (headers: any) => {
            headers.set("Authorization", `Token ${localStorage.getItem('cp-token')}`)
            return headers
        }
    }),
    tagTypes:['banner'],
    
    endpoints:(builder)=>({
        getBaners:builder.query<any,void>({
            query:()=>({
                url:urlConstants.BANNER ,
                method:'GET',
            }),
            providesTags:['banner']
        })
    })

})

export const {
useGetBanersQuery
}=bannerApi