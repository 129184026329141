import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { config } from '../../../config'
import { urlConstants } from '../../../constants/urlConstants'

export const leadsApi = createApi({
    reducerPath: 'leadsApi',
    baseQuery: fetchBaseQuery({
        baseUrl: config().BASE_URL,
        prepareHeaders: (headers: any) => {
            headers.set("Authorization", `Token ${localStorage.getItem('cp-token')}`)
            return headers
        }
    }),
    
    tagTypes: ['leads'],
    endpoints: (builder) => ({
        getLeads: builder.query<any, any>({
            query: ({searchString,page}) => ({
                url: '/lead',
                method: 'GET',
                params:{
                    searchString: searchString,
                    page:page,
                    // limit:1
                }
            }),
            providesTags: ['leads']
        }),

        createLead: builder.mutation<any, void>({
            query: (data) => ({
                url: urlConstants.LEAD,
                method: 'POST',
                body:data
            }),
            invalidatesTags: ['leads']
        }),

        getSingleLead: builder.query<any, void>({
            query: (id: any) => ({
                url: `/lead/${id}`,
                method: 'GET'
            }),
            providesTags: ['leads']
        }),

        editLead: builder.mutation<any, any>({
            query: ({id,leadData}:any) => ({
                url: `/lead/${id}`,
                method: 'PUT',
                body:leadData
            }),
            invalidatesTags: ['leads']
        }),
        deletetLead: builder.mutation<any, any>({
            query: (id:any) => ({
                url: `/lead/${id}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['leads']
        })
    })

})

export const { useGetLeadsQuery, useCreateLeadMutation, useEditLeadMutation, useGetSingleLeadQuery ,useDeletetLeadMutation} = leadsApi